import React, { useContext } from "react";
import { Typography, Chip, Divider, Link } from "@mui/material";
import { DataContext } from "../../../context/context";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../Payment.css"

const TenantInfo = () => {
  const { tenantData } = useContext(DataContext);

  return (
    <>
      <Typography variant="h1" my={2}>
        Quick Pay
      </Typography>
      <Typography variant="body1" mt={1} mb={2}>
        A simple way to make a one-time payment for your account. Or <Link href={process.env.REACT_APP_UTILITIES_INFO_URL} color="secondary">log in</Link> to pay.
      </Typography>
      <Divider>
        <Chip label="Account Info" variant="outlined" />
      </Divider>
      <Typography gutterBottom variant="h2" my={3}>
        Last Name: {tenantData.paymentInfo.lastName}
      </Typography>
      <Typography gutterBottom variant="h2" my={3}>
        Account #: xxxx{tenantData.paymentInfo.tenantCode.slice(-4)}
      </Typography>
      <Divider>
        <Chip label="Payment Info" variant="outlined" />
      </Divider>
      {tenantData.paymentInfo.pendingPayments.length > 0 && (
        tenantData.paymentInfo.pendingPayments.length > 1 ? (
          <Typography className="information-notification-bubble" variant="body2" mt={2}>
            <FontAwesomeIcon icon={faCircleInfo} /> Multiple payments are pending.
          </Typography>
        ) : (
          tenantData.paymentInfo.pendingPayments.map((payment, index) => {
            const date = new Date(payment.paymentAt);
            const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
            return (
              <Typography className="information-notification-bubble" variant="body2" mt={2} key={index}>
                <FontAwesomeIcon icon={faCircleInfo} /> A pending payment of ${payment.paymentAmount.toFixed(2)} was made on {formattedDate}.
              </Typography>
            )
          }
          ))
      )}

      <Typography gutterBottom variant="h2" mt={3}>
        Current Balance: ${tenantData.paymentInfo.currentBalance.toFixed(2)}
      </Typography>
    </>
  );
};

export default TenantInfo;
