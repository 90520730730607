import React, { useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  Chip,
  Divider,
  FormGroup,
  MenuItem,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import NameValueItem from "../../../interfaces/helperInterfaces";
import { DataContext } from "../../../context/context";
import { OneTimeCCPaymentRequest, OneTimeCCPaymentResponse } from "../../../interfaces/tenantQuickPayPaymentInterfaces";
import { tenantQuickPayService } from "../../../services/tenantQuickPayService";
import { GetCreditCardIssuerFee, GetPaymentFee } from "../../../utils/helpers/feeHelper";
import { formValidations } from "../../../utils/validations/formValidations";
import ReviewTerms from "./ReviewTerms";
import { useNavigate } from "react-router-dom";
import ErrorNotificationModal from "./ErrorNotificationModal";
import ReactGA from "react-ga4";

const monthOptions = [
  { name: "01 - January", value: "01" },
  { name: "02 - February", value: "02" },
  { name: "03 - March", value: "03" },
  { name: "04 - April", value: "04" },
  { name: "05 - May", value: "05" },
  { name: "06 - June", value: "06" },
  { name: "07 - July", value: "07" },
  { name: "08 - August", value: "08" },
  { name: "09 - September", value: "09" },
  { name: "10 - October", value: "10" },
  { name: "11 - November", value: "11" },
  { name: "12 - December", value: "12" },
] as NameValueItem[];

const countryOptions = [
  { name: "United States", value: "US" },
  { name: "Canada", value: "CA" },
] as NameValueItem[];

const today = new Date();
const baseYear = today.getFullYear();
const yearOptions = Array.from(Array(10)).map((_, index) =>
  (index + baseYear).toString()
);

const CreditCardForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { tenantData, confirmationData, setConfirmationData } = useContext(DataContext);

  const defaultBalance: number = +tenantData.paymentInfo.currentBalance.toFixed(2);
  const [paymentAmount, setPayemntAmount] = useState(defaultBalance);
  const [creditCardFee, setCreditCardFee] = useState(tenantData.paymentInfo.defaultCreditCardFee);
  const [feeAmount, setFeeAmount] = useState(GetPaymentFee(tenantData.paymentInfo, paymentAmount, tenantData.paymentInfo.defaultCreditCardFee));
  const [error, setError] = useState(false);

  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<OneTimeCCPaymentRequest>({ mode: "all" });

  const onSubmit: SubmitHandler<OneTimeCCPaymentRequest> = async (data) => {
    setIsLoading(true);

    data.authToken = tenantData.authToken;
    data.token = tenantData.token ?? "";
    data.amount = +data.amount; // convert to number
    data.note = " Quick Pay Credit Card by web - CS ";

    try {
      const oneTimePaymentResponse: OneTimeCCPaymentResponse = await tenantQuickPayService.makeOneTimeCCPayment(data);
      
      setConfirmationData(oneTimePaymentResponse);

      if (oneTimePaymentResponse.isSuccessful) {

        ReactGA.event({ category: "Payment", action: "CreditCardForm Submit", label: "Success" });

        setIsLoading(false);
        navigate("/payment-confirmation", { replace: true });

      } else {
        setIsLoading(false);
        setError(true);

        ReactGA.event({ category: "Payment", action: "CreditCardForm Submit", label: "Failed" });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeAmount = (e: any) => {
    const value = e.target.value;
    const fee = GetPaymentFee(tenantData.paymentInfo, value, creditCardFee);
    setPayemntAmount(value);
    setFeeAmount(fee);
  };

  const changeCardNumber = (e: any) => {
    const value = e.target.value;
    setCreditCardFee(GetCreditCardIssuerFee(value, tenantData));
    const fee = GetPaymentFee(tenantData.paymentInfo, paymentAmount, creditCardFee);
    setFeeAmount(fee);
  };

  const errorHandler = () => {
    setError(false);
  };

  return (
    <>
      <Divider>
        <Chip label="Card Info" variant="outlined" />
      </Divider>
      {error && (
        <ErrorNotificationModal
          openModal={error}
          onClose={errorHandler}
          errorMessage={confirmationData?.errorMessage}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Name on Card"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          error={!!errors.nameOnCard}
          inputProps={{ maxLength: 75 }}
          helperText={errors.nameOnCard ? errors.nameOnCard?.message : ""}
          {...register("nameOnCard", {
            required: "Name on Card is required",
            maxLength: {
              value: 75,
              message: "Name exceeds maximum length",
            },
            validate: (nameOnCard) =>
              formValidations.nonAsciiCommasCheck(nameOnCard),
          })}
        />
        <TextField
          label="Card Number"
          className={"textbox-width"}
          autoComplete="off"
          inputProps={{ maxLength: 19 }}
          sx={{ mt: 2 }}
          error={!!errors.cardNumber}
          helperText={errors.cardNumber ? errors.cardNumber?.message : ""}
          {...register("cardNumber", {
            required: "Card Number is required",
            pattern: {
              value: /^\d{12,19}$/,
              message: "Invalid Card Number",
            },
            maxLength: {
              value: 19,
              message: "Invalid Card Number",
            },
            validate: (cardNumber) => formValidations.luhnCheck(cardNumber),
            onChange: (e) => {
              changeCardNumber(e);
            },
          })}
        />
        <FormGroup className={"align-content-center"}>
          <TextField
            select
            label="Expiration Month"
            className={"textbox-width"}
            defaultValue=""
            sx={{ textAlign: "left", mt: 2 }}
            InputLabelProps={{ component: "div" }}
            inputProps={register("expiryMonth", {
              required: "Expiration Month is required",
              validate: (month) =>
                formValidations.validateMonth(month, getValues()),
              onChange: (e) =>
                setValue("expiryMonth", e.target.value, {
                  shouldValidate: true,
                }),
            })}
            error={!!errors.expiryMonth}
            helperText={errors.expiryMonth?.message}
          >
            {monthOptions.map((option) => (
              <MenuItem key={option.name} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Expiration Year"
            className={"textbox-width"}
            defaultValue=""
            sx={{ textAlign: "left", mt: 2 }}
            InputLabelProps={{ component: "div" }}
            inputProps={register("expiryYear", {
              required: "Expiration Year is required",
              validate: (year) =>
                formValidations.validateYear(year, getValues()),
              onChange: (e) =>
                setValue("expiryYear", e.target.value, {
                  shouldValidate: true,
                }),
            })}
            error={!!errors.expiryYear}
            helperText={errors.expiryYear?.message}
          >
            {yearOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <TextField
          label="Billing Address"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 100 }}
          error={!!errors.address1}
          helperText={errors.address1 ? errors.address1?.message : ""}
          {...register("address1", {
            required: "Billing Address is required",
            maxLength: {
              value: 100,
              message: "Billing Address exceeds max length",
            },
            validate: (address1) =>
              formValidations.nonAsciiCommasCheck(address1),
          })}
        />
        <TextField
          label="Billing City"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 40 }}
          error={!!errors.city}
          helperText={errors.city ? errors.city?.message : ""}
          {...register("city", {
            required: "Billing City is required",
            maxLength: {
              value: 40,
              message: "Billing City exceeds maximum length",
            },
            validate: (city) => formValidations.nonAsciiCommasCheck(city),
          })}
        />
        <TextField
          label="Billing State"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ textAlign: "left", mt: 2 }}
          inputProps={{ maxLength: 2 }}
          error={!!errors.state}
          helperText={errors.state ? errors.state?.message : ""}
          {...register("state", {
            required: "Billing State Code is required",
            maxLength: {
              value: 2,
              message:
                "Billing State should be the 2 letter code for your state",
            },
            validate: (state) => formValidations.nonAsciiCommasCheck(state),
          })}
        />

        <TextField
          label="Billing Zip/Postal Code"
          className={"textbox-width"}
          autoComplete="off"
          inputProps={{ maxLength: 7 }}
          sx={{ mt: 2 }}
          error={!!errors.postalCode}
          helperText={errors.postalCode ? errors.postalCode?.message : ""}
          {...register("postalCode", {
            required: "Billing Zip/Postal Code is required",
            maxLength: {
              value: 7,
              message: "Invalid Zip/Postal Code",
            },
            pattern: {
              value:
                /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstv‌​xy]{1} ?\d{1}[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvxy]{1}\d{1}$)/,
              message: "Invalid Zip/Postal Code",
            },
          })}
        />
        <TextField 
          label="Country"
          id="Country"
          select
          className={"textbox-width"}
          defaultValue={"US"}
          sx={{ textAlign: "left", mt: 2 }}
          InputLabelProps={{ component: "div" }}
          inputProps={register("country", {
            required: "Country is required",
          })}
          error={!!errors.country}
          helperText={errors.country?.message}
        >
          {countryOptions.map((option) => (
            <MenuItem key={option.name} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Email Confirmation"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 75 }}
          error={!!errors.email}
          helperText={errors.email ? errors.email?.message : ""}
          {...register("email", {
            required: "Confirmation Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/,
              message: "Invalid Email Address",
            },
            maxLength: {
              value: 75,
              message: "Email address exceeds maximum length",
            },
          })}
        />
        <ReviewTerms />
        <FormGroup className={"align-content-center"}>
          <TextField
            label="Amount"
            className={"textbox-width"}
            defaultValue={paymentAmount}
            sx={{ mt: 2 }}
            type="number"
            inputProps={{
              inputMode: "numeric",
              min: 0.01,
              step: 0.01,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...register("amount", {
              required: "Amount is required",
              min: {
                value: 5.0,
                message: "Amount must be greater than $5.00",
              },
              pattern: {
                value: /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/,
                message: "Only 2 decimal points allowed",
              },
              onChange: (e) => {
                onChangeAmount(e);
              },
            })}
            error={!!errors.amount}
            helperText={errors.amount ? errors.amount?.message : ""}
          ></TextField>
        </FormGroup>

        <Typography gutterBottom variant="h2" my={3}>
          Convenience Fee: ${feeAmount.toFixed(2)}
        </Typography>
        <Typography gutterBottom variant="h2" my={3}>
          Total Payment: ${(+paymentAmount + +feeAmount).toFixed(2)}
        </Typography>
        <Button
          disabled={isLoading}
          type="submit"
          sx={{ my: 2 }}
          variant="contained"
          color="secondary"
          size="large"
        >
          Submit
        </Button>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreditCardForm;
