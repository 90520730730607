import React from 'react';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Modal from "@mui/material/Modal";
import './BillInfoModal.css';

function BillInfoModal() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div >
            <IconButton onClick={() => handleOpen()} style={{ marginRight: '0px' }} aria-label="info">
                <InfoIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box id="bill-modal-content-wrapper">
                    <Box className="bill-modal-content">
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <h3 ><strong>Physical Bill</strong></h3>
                            <p>Please keep the following in mind:</p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>The last four digits of your account number can be found on your Physical Bill.</li>
                                <li>The highlighted part is the last four digits of your Account Number. </li>
                                <li>Enter the information from your statement exactly as it appears.</li>
                            </ul>
                        </Typography>

                        <img className='responsive-image' src={require('../../assets/photos/pbill.PNG')} alt='physical bill' />
                    <Divider variant="middle" />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <h3 ><strong>Digital Bill</strong></h3>
                            <p>Please keep the following in mind:</p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>The last four digits of your account number can be found on your Digital Bill.</li>
                                <li>The highlighted part is the last four digits of your Account Number.</li>
                                <li>Enter the information from your statement exactly as it appears.</li>
                            </ul>
                        </Typography>
                        <img className='responsive-image' src={require('../../assets/photos/ebill.PNG')} alt='digital bill' />
                    </Box>
                    <Button color="secondary" onClick={handleClose} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}

export default BillInfoModal;