import { createContext, useEffect, useState } from "react";
import { OneTimeCCPaymentResponse, TenantInformationResponse } from "../interfaces/tenantQuickPayPaymentInterfaces";
import { SearchResponse } from "../interfaces/accountVerificationInterfaces";

type DataContextType = {
  confirmationData: OneTimeCCPaymentResponse;
  setConfirmationData: React.Dispatch<React.SetStateAction<any>>;
  tenantData: TenantInformationResponse;
  setTenantData: React.Dispatch<React.SetStateAction<any>>;
  searchData: SearchResponse;
  setSearchData: React.Dispatch<React.SetStateAction<any>>;
};

export const DataContext = createContext<DataContextType>({} as DataContextType);

function load(key: string) {
  const item = window.sessionStorage.getItem(key);
  return item != null ? JSON.parse(item) : {};
}

export default function DataProvider({ children }: { children: React.ReactNode; }) {
  const [tenantData, setTenantData] = useState<TenantInformationResponse>(load('tenantData'));
  const [confirmationData, setConfirmationData] = useState<OneTimeCCPaymentResponse>(load('confirmationData'));
  const [searchData, setSearchData] = useState<SearchResponse>(load('searchData'));

  useEffect(() => {
    // Save tenantData to session storage whenever it changes
    sessionStorage.setItem("tenantData", JSON.stringify(tenantData));
  }, [tenantData]); // Runs when tenantData changes

  useEffect(() => {
    // Save confirmationData to session storage whenever it changes
    sessionStorage.setItem("confirmationData", JSON.stringify(confirmationData));
  }, [confirmationData]); // Runs when confirmationData change

  useEffect(() => {
    // Save searchData to session storage whenever it changes
    sessionStorage.setItem("searchData", JSON.stringify(searchData));
  }, [searchData]); // Runs when searchData change

  return (
    <DataContext.Provider
      value={{
        confirmationData,
        setConfirmationData,
        tenantData,
        setTenantData,
        searchData,
        setSearchData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
